var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "contact-form section mx-auto"
  }, [_c('div', [_c('div', {
    staticClass: "justify-content-center",
    staticStyle: {
      "max-width": "615px",
      "margin": "auto"
    }
  }, [_c('div', {
    staticClass: "p-0 m-0 w-100"
  }, [_c('h2', {
    staticClass: "light-theme-title text-center",
    class: _vm.$i18n.locale == 'ja' ? 'customH2Jp' : 'customH2'
  }, [_vm._v(" " + _vm._s(_vm.$t("help.form.title")) + " "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v(" " + _vm._s(_vm.$t("help.form.title2")) + " ")])]), _c('h4', {
    staticClass: "text-center subtitle-bodyLarge light-grey-dark"
  }, [_vm._v(" " + _vm._s(_vm.$t("help.form.sub-title")) + " ")]), _c('div', {
    staticClass: "main-form"
  }, [_c('p', {
    staticClass: "text-uppercase mt-4 selectType",
    staticStyle: {
      "margin": "26px auto 24px auto"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("help.form.select")) + " ")]), _c('div', {
    staticClass: "contact-buttons d-flex flex-column flex-md-row"
  }, [_c('b-button', {
    staticClass: "contact-button d-block w-100",
    class: {
      active: _vm.type === 'New Client'
    },
    on: {
      "click": function ($event) {
        return _vm.handleClick('New Client');
      }
    }
  }, [_c('p', {
    staticClass: "button-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("help.form.new-client.title")) + " ")])]), _c('b-button', {
    staticClass: "contact-button d-block w-100",
    class: {
      active: _vm.type === 'Existing Client'
    },
    on: {
      "click": function ($event) {
        return _vm.handleClick('Existing Client');
      }
    }
  }, [_c('p', {
    staticClass: "button-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("help.form.existing client.title")) + " ")])]), _c('b-button', {
    staticClass: "contact-button d-block w-100",
    class: {
      active: _vm.type === 'Partnership'
    },
    on: {
      "click": function ($event) {
        return _vm.handleClick('Partnership');
      }
    }
  }, [_c('p', {
    staticClass: "button-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("help.form.partnership.title")) + " ")])])], 1), _vm.type === 'New Client' ? _c('div', {
    staticClass: "mt-5 mt-md-0"
  }, [_c('form', [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "nameAndSurname"
    }
  }, [_vm._v(_vm._s(_vm.$t("help.form.new-client.nameAndSurname")) + "*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.nameAndSurname,
      expression: "nameAndSurname"
    }],
    staticClass: "input",
    class: _vm.$v.nameAndSurname.$error ? 'is-invalid' : '',
    attrs: {
      "type": "text",
      "id": "nameAndSurname",
      "placeholder": _vm.$t('complaint form.placeholder.name'),
      "required": ""
    },
    domProps: {
      "value": _vm.nameAndSurname
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.nameAndSurname = $event.target.value;
      }
    }
  }), !_vm.$v.nameAndSurname.required & _vm.$v.nameAndSurname.$dirty ? _c('span', {
    staticClass: "red-info"
  }, [_vm._v(" Please complete this required field. ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v(_vm._s(_vm.$t("help.form.new-client.email")) + "*")]), _c('b-form-input', {
    staticClass: "input",
    class: _vm.$v.email.$error ? 'is-invalid' : '',
    attrs: {
      "type": "email",
      "id": "email",
      "placeholder": _vm.$t('complaint form.placeholder.email')
    },
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), !_vm.$v.email.required & _vm.$v.email.$dirty ? _c('span', {
    staticClass: "red-info"
  }, [_vm._v(" Please complete this required field. ")]) : _vm._e(), _vm.$v.email.required & !_vm.$v.email.email ? _c('span', {
    staticClass: "red-info"
  }, [_vm._v(" Your email is not valid ")]) : _vm._e()], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("help.form.new-client.mobile")) + "*")]), _c('MazPhoneNumberInput', {
    staticClass: "mb-32px",
    class: _vm.$v.email.$error ? 'is-invalid-1' : '',
    attrs: {
      "type": "tel",
      "default-country-code": _vm.defaultCountryCode
    },
    on: {
      "update": function ($event) {
        _vm.results = $event;
      }
    },
    model: {
      value: _vm.phoneNumber,
      callback: function ($$v) {
        _vm.phoneNumber = $$v;
      },
      expression: "phoneNumber"
    }
  }), !_vm.$v.phoneNumber.required & _vm.$v.phoneNumber.$dirty ? _c('span', {
    staticClass: "red-info"
  }, [_vm._v(" Please complete this required field. ")]) : _vm._e()], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "message"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("help.form.new-client.message")) + "* ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.message,
      expression: "message"
    }],
    staticClass: "input",
    class: _vm.$v.message.$error ? 'is-invalid' : '',
    staticStyle: {
      "height": "156px !important"
    },
    attrs: {
      "id": "message",
      "cols": "30",
      "rows": "10"
    },
    domProps: {
      "value": _vm.message
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.message = $event.target.value;
      }
    }
  }), !_vm.$v.message.required & _vm.$v.message.$dirty ? _c('span', {
    staticClass: "red-info"
  }, [_vm._v(" Please complete this required field. ")]) : _vm._e()])])]) : _vm._e(), _vm.type === 'Existing Client' ? _c('div', {
    staticClass: "mt-5 mt-md-0"
  }, [_c('form', [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v(_vm._s(_vm.$t("help.form.new-client.email")) + "*")]), _c('b-form-input', {
    staticClass: "input",
    class: _vm.$v.email.$error ? 'is-invalid' : '',
    attrs: {
      "type": "text",
      "id": "email",
      "placeholder": _vm.$t('complaint form.placeholder.email'),
      "required": ""
    },
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), !_vm.$v.email.required & _vm.$v.email.$dirty ? _c('span', {
    staticClass: "red-info"
  }, [_vm._v(" Please complete this required field. ")]) : _vm._e(), _vm.$v.email.required & !_vm.$v.email.email ? _c('span', {
    staticClass: "red-info"
  }, [_vm._v(" Your email is not valid ")]) : _vm._e()], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "message"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("help.form.new-client.message")) + "* ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.message,
      expression: "message"
    }],
    staticClass: "input",
    class: _vm.$v.message.$error ? 'is-invalid' : '',
    staticStyle: {
      "height": "156px !important"
    },
    attrs: {
      "id": "message",
      "cols": "30",
      "rows": "10",
      "required": ""
    },
    domProps: {
      "value": _vm.message
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.message = $event.target.value;
      }
    }
  }), !_vm.$v.message.required & _vm.$v.message.$dirty ? _c('span', {
    staticClass: "red-info"
  }, [_vm._v(" Please complete this required field. ")]) : _vm._e()])])]) : _vm._e(), _vm.type === 'Partnership' ? _c('div', {
    staticClass: "mt-5 mt-md-0"
  }, [_c('form', [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v(_vm._s(_vm.$t("help.form.new-client.email")) + "*")]), _c('b-form-input', {
    staticClass: "input",
    class: _vm.$v.email.$error ? 'is-invalid' : '',
    attrs: {
      "type": "text",
      "id": "email",
      "placeholder": _vm.$t('complaint form.placeholder.email'),
      "required": ""
    },
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), !_vm.$v.email.required & _vm.$v.email.$dirty ? _c('span', {
    staticClass: "red-info"
  }, [_vm._v(" Please complete this required field. ")]) : _vm._e(), _vm.$v.email.required & !_vm.$v.email.email ? _c('span', {
    staticClass: "red-info"
  }, [_vm._v(" Your email is not valid ")]) : _vm._e()], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "message"
    }
  }, [_vm._v(_vm._s(_vm.$t("help.form.new-client.message")) + "* ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.message,
      expression: "message"
    }],
    staticClass: "input",
    class: _vm.$v.message.$error ? 'is-invalid' : '',
    staticStyle: {
      "height": "156px !important"
    },
    attrs: {
      "id": "message",
      "cols": "30",
      "rows": "10",
      "required": ""
    },
    domProps: {
      "value": _vm.message
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.message = $event.target.value;
      }
    }
  }), !_vm.$v.message.required & _vm.$v.message.$dirty ? _c('span', {
    staticClass: "red-info"
  }, [_vm._v(" Please complete this required field. ")]) : _vm._e()])])]) : _vm._e(), _vm.type !== '' ? _c('b-button', {
    staticClass: "round-btn-16-24 d-block mx-auto",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.submitContactForm
    }
  }, [_c('p', {
    staticClass: "button-medium text-white-button"
  }, [_vm._v(" " + _vm._s(_vm.$t("help.form.send message")) + " ")])]) : _vm._e()], 1)]), _c('b-modal', {
    staticClass: "modal-contact-form",
    attrs: {
      "centered": "",
      "id": "modal-contact-form",
      "size": "md",
      "hide-header": true,
      "hide-footer": true,
      "no-close-on-backdrop": true,
      "no-close-on-esc": true
    }
  }, [_c('p', {
    staticClass: "text-right",
    on: {
      "click": function ($event) {
        return _vm.$bvModal.hide('modal-contact-form');
      }
    }
  }, [_c('CloseForm')], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('ContactFormSuccess', {
    staticClass: "mb-22px"
  }), _c('p', {
    staticClass: "modal-contact-form__title roboto"
  }, [_vm._v("Thank you!")]), _c('p', {
    staticClass: "modal-contact-form__body"
  }, [_vm._v(" We have received your message. We will contact you soon. ")]), _c('b-button', {
    staticClass: "button-done",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.hide('modal-contact-form');
      }
    }
  }, [_c('span', {
    staticClass: "text-white-button button-medium"
  }, [_vm._v(" Done ")])])], 1)])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }