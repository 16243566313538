var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_5562_53514)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M13.3327 0.832031H3.33268C2.41602 0.832031 1.66602 1.58203 1.66602 2.4987V14.1654H3.33268V2.4987H13.3327V0.832031ZM15.8327 4.16536H6.66602C5.74935 4.16536 4.99935 4.91536 4.99935 5.83203V17.4987C4.99935 18.4154 5.74935 19.1654 6.66602 19.1654H15.8327C16.7493 19.1654 17.4993 18.4154 17.4993 17.4987V5.83203C17.4993 4.91536 16.7493 4.16536 15.8327 4.16536ZM15.8327 17.4987H6.66602V5.83203H15.8327V17.4987Z",
      "fill": "#484C57"
    }
  })]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0_5562_53514"
    }
  }, [_c('rect', {
    attrs: {
      "width": "20",
      "height": "20",
      "fill": "white"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }