<template>
  <div class="contact-form section mx-auto">
    <div>
      <div
        class="justify-content-center"
        style="max-width: 615px; margin: auto"
      >
        <div class="p-0 m-0 w-100">
          <h2
            class="light-theme-title text-center"
            :class="$i18n.locale == 'ja' ? 'customH2Jp' : 'customH2'"
          >
            {{ $t("help.form.title") }}
            <span class="text-primary">
              {{ $t("help.form.title2") }}
            </span>
          </h2>
          <h4 class="text-center subtitle-bodyLarge light-grey-dark">
            {{ $t("help.form.sub-title") }}
          </h4>
          <div class="main-form">
            <p
              class="text-uppercase mt-4 selectType"
              style="margin: 26px auto 24px auto"
            >
              {{ $t("help.form.select") }}
            </p>

            <div class="contact-buttons d-flex flex-column flex-md-row">
              <b-button
                @click="handleClick('New Client')"
                class="contact-button d-block w-100"
                :class="{ active: type === 'New Client' }"
              >
                <p class="button-text">
                  {{ $t("help.form.new-client.title") }}
                </p></b-button
              >
              <b-button
                @click="handleClick('Existing Client')"
                class="contact-button d-block w-100"
                :class="{ active: type === 'Existing Client' }"
              >
                <p class="button-text">
                  {{ $t("help.form.existing client.title") }}
                </p>
              </b-button>
              <b-button
                @click="handleClick('Partnership')"
                class="contact-button d-block w-100"
                :class="{ active: type === 'Partnership' }"
              >
                <p class="button-text">
                  {{ $t("help.form.partnership.title") }}
                </p>
              </b-button>
            </div>

            <div v-if="type === 'New Client'" class="mt-5 mt-md-0">
              <form>
                <div class="form-group">
                  <label class="" for="nameAndSurname"
                    >{{ $t("help.form.new-client.nameAndSurname") }}*</label
                  >
                  <input
                    class="input"
                    type="text"
                    id="nameAndSurname"
                    v-model="nameAndSurname"
                    :placeholder="$t('complaint form.placeholder.name')"
                    required
                    :class="$v.nameAndSurname.$error ? 'is-invalid' : ''"
                  />

                  <span
                    v-if="
                      !$v.nameAndSurname.required & $v.nameAndSurname.$dirty
                    "
                    class="red-info"
                  >
                    Please complete this required field.
                  </span>
                </div>

                <div class="form-group">
                  <label class="" for="email"
                    >{{ $t("help.form.new-client.email") }}*</label
                  >
                  <b-form-input
                    class="input"
                    type="email"
                    id="email"
                    v-model="email"
                    :placeholder="$t('complaint form.placeholder.email')"
                    :class="$v.email.$error ? 'is-invalid' : ''"
                  />
                  <span
                    v-if="!$v.email.required & $v.email.$dirty"
                    class="red-info"
                  >
                    Please complete this required field.
                  </span>
                  <span
                    v-if="$v.email.required & !$v.email.email"
                    class="red-info"
                  >
                    Your email is not valid
                  </span>
                </div>

                <div class="form-group">
                  <label>{{ $t("help.form.new-client.mobile") }}*</label>
                  <MazPhoneNumberInput
                    type="tel"
                    v-model="phoneNumber"
                    :default-country-code="defaultCountryCode"
                    @update="results = $event"
                    class="mb-32px"
                    :class="$v.email.$error ? 'is-invalid-1' : ''"
                  />
                  <span
                    v-if="!$v.phoneNumber.required & $v.phoneNumber.$dirty"
                    class="red-info"
                  >
                    Please complete this required field.
                  </span>
                </div>

                <div class="form-group">
                  <label class="" for="message">
                    {{ $t("help.form.new-client.message") }}*
                  </label>
                  <textarea
                    id="message"
                    class="input"
                    style="height: 156px !important"
                    cols="30"
                    rows="10"
                    v-model="message"
                    :class="$v.message.$error ? 'is-invalid' : ''"
                  ></textarea>
                  <span
                    v-if="!$v.message.required & $v.message.$dirty"
                    class="red-info"
                  >
                    Please complete this required field.
                  </span>
                </div>
              </form>
            </div>
            <div v-if="type === 'Existing Client'" class="mt-5 mt-md-0">
              <form>
                <div class="form-group">
                  <label class="" for="email"
                    >{{ $t("help.form.new-client.email") }}*</label
                  >
                  <b-form-input
                    class="input"
                    type="text"
                    id="email"
                    v-model="email"
                    :placeholder="$t('complaint form.placeholder.email')"
                    required
                    :class="$v.email.$error ? 'is-invalid' : ''"
                  />
                  <span
                    v-if="!$v.email.required & $v.email.$dirty"
                    class="red-info"
                  >
                    Please complete this required field.
                  </span>
                  <span
                    v-if="$v.email.required & !$v.email.email"
                    class="red-info"
                  >
                    Your email is not valid
                  </span>
                </div>

                <div class="form-group">
                  <label class="" for="message">
                    {{ $t("help.form.new-client.message") }}*
                  </label>
                  <textarea
                    id="message"
                    class="input"
                    style="height: 156px !important"
                    cols="30"
                    rows="10"
                    v-model="message"
                    required
                    :class="$v.message.$error ? 'is-invalid' : ''"
                  ></textarea>
                  <span
                    v-if="!$v.message.required & $v.message.$dirty"
                    class="red-info"
                  >
                    Please complete this required field.
                  </span>
                </div>
              </form>
            </div>
            <div v-if="type === 'Partnership'" class="mt-5 mt-md-0">
              <form>
                <div class="form-group">
                  <label class="" for="email"
                    >{{ $t("help.form.new-client.email") }}*</label
                  >
                  <b-form-input
                    class="input"
                    type="text"
                    id="email"
                    v-model="email"
                    :placeholder="$t('complaint form.placeholder.email')"
                    required
                    :class="$v.email.$error ? 'is-invalid' : ''"
                  />
                  <span
                    v-if="!$v.email.required & $v.email.$dirty"
                    class="red-info"
                  >
                    Please complete this required field.
                  </span>
                  <span
                    v-if="$v.email.required & !$v.email.email"
                    class="red-info"
                  >
                    Your email is not valid
                  </span>
                </div>

                <div class="form-group">
                  <label class="" for="message"
                    >{{ $t("help.form.new-client.message") }}*
                  </label>
                  <textarea
                    id="message"
                    class="input"
                    style="height: 156px !important"
                    cols="30"
                    rows="10"
                    v-model="message"
                    required
                    :class="$v.message.$error ? 'is-invalid' : ''"
                  ></textarea>
                  <span
                    v-if="!$v.message.required & $v.message.$dirty"
                    class="red-info"
                  >
                    Please complete this required field.
                  </span>
                </div>
              </form>
            </div>
            <b-button
              v-if="type !== ''"
              variant="primary"
              class="round-btn-16-24 d-block mx-auto"
              @click="submitContactForm"
            >
              <p class="button-medium text-white-button">
                {{ $t("help.form.send message") }}
              </p>
            </b-button>
          </div>
        </div>
        <b-modal
          centered
          id="modal-contact-form"
          size="md"
          :hide-header="true"
          :hide-footer="true"
          :no-close-on-backdrop="true"
          :no-close-on-esc="true"
          class="modal-contact-form"
        >
          <p @click="$bvModal.hide('modal-contact-form')" class="text-right">
            <CloseForm />
          </p>

          <div class="text-center">
            <ContactFormSuccess class="mb-22px"></ContactFormSuccess>
            <p class="modal-contact-form__title roboto">Thank you!</p>
            <p class="modal-contact-form__body">
              We have received your message. We will contact you soon.
            </p>
            <b-button
              variant="primary"
              class="button-done"
              @click="$bvModal.hide('modal-contact-form')"
            >
              <span class="text-white-button button-medium"> Done </span>
            </b-button>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import CloseForm from "../svg/contactForm/CLoseForm.vue";
import ContactFormSuccess from "../svg/contactForm/ContactFormSuccess.vue";
import RedInfo from "../svg/contactForm/RedInfo.vue";
import { countriess } from "../../../../FLAGS_TEST/countries";
import MazPhoneNumberInput from "maz-ui/lib/maz-phone-number-input";
import "maz-ui/lib/css/base.css";
import "maz-ui/lib/css/maz-phone-number-input.css";
import { validationMixin } from "vuelidate";
const { required, email } = require("vuelidate/lib/validators");

export default {
  components: {
    MazPhoneNumberInput,
    CloseForm,
    ContactFormSuccess,
    RedInfo,
  },
  mixins: [validationMixin],
  validations: {
    nameAndSurname: { required },
    email: { required, email },
    mobile: { required },
    message: { required },
    type: { required },
    phoneNumber: { required },
  },
  created() {
    this.options = countriess.map((c) => ({
      ...c,
      imageFile: `${c.name.toLowerCase()}.png`,
    }));
  },
  filters: {
    url(value) {
      return require("@/FLAGS_TEST/" + value);
    },
  },
  data() {
    return {
      nameAndSurname: "",
      email: "",
      mobile: "",
      message: "",
      type: "",
      phoneNumber: null,
    };
  },
  methods: {
    async submitContactForm() {
      this.$v.nameAndSurname.$touch();
      this.$v.email.$touch();
      this.$v.message.$touch();
      this.$v.phoneNumber.$touch();
      if (this.type == "New Client") {
        if (this.$v.email.$pending || this.$v.email.$error) return;
        if (this.$v.nameAndSurname.$pending || this.$v.nameAndSurname.$error)
          return;
        if (this.$v.message.$pending || this.$v.message.$error) return;
        if (this.$v.phoneNumber.$pending || this.$v.phoneNumber.$error) return;
      }
      if (this.type == "Existing Client" || this.type == "Partnership") {
        if (this.$v.email.$pending || this.$v.email.$error) return;
        if (this.$v.message.$pending || this.$v.message.$error) return;
      }

      try {
        const res = await this.$axios.post(`contact_messages`, {
          full_name: this.nameAndSurname,
          email: this.email,
          mobile_phone: this.phoneNumber,
          message: this.message,
          category: this.type,
        });

        (this.nameAndSurname = ""),
          (this.email = ""),
          (this.mobile = ""),
          (this.message = ""),
          (this.phoneNumber = ""),
          (this.type = "");
      } catch (error) {
        console.error(error);
      } finally {
        this.$bvModal.show("modal-contact-form");
      }
    },
    handleClick(type) {
      (this.type = type),
        (this.nameAndSurname = ""),
        (this.email = ""),
        (this.mobile = ""),
        (this.message = ""),
        (this.phoneNumber = "");
      this.$v.nameAndSurname.$reset();
      this.$v.email.$reset();
      this.$v.mobile.$reset();
      this.$v.message.$reset();
      this.$v.phoneNumber.$reset();
    },
  },
  computed: {
    defaultCountryCode() {
      return localStorage.getItem("country") ? (localStorage.getItem("country")) : "ME"
    }
  }
};
</script>
<style lang="scss" scoped>
.is-invalid {
  border: 2px solid var(--danger);
}
.is-invalid-1 ::v-deep .input-phone-number {
  border: 2px solid var(--danger);
}

.is-invalid-2 ::v-deep .custom-file-label {
  border: 2px solid var(--danger);
}
.red-info {
  display: flex;
  align-items: center;
  color: red;
  margin-top: -30px;
  margin-bottom: 20px;
}

::v-deep .modal-content {
  border-radius: 24px;
  @media (max-width: 767px) {
    width: 340px;
    margin: auto;
  }
}
::v-deep .modal-body {
  padding: 28px;
  @media (max-width: 767px) {
    padding: 24px;
  }
}
.mb-22px {
  margin-bottom: 22px;
}
.modal-contact-form__title {
  height: 29px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  color: #39454c;
}
.modal-contact-form__body {
  margin: auto;
  width: 255px;
  height: 38px;
  //font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #616a70;
  margin-bottom: 22px;
}
.button-done {
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 164px;
  height: 48px;
  background: #07b53b;
  border-radius: 90px;
  margin-bottom: 0;
}
.round-btn-16-24 {
  margin-top: 32px !important;
  width: 200px !important;
}
.customH2 {
  margin: auto;
  @media (max-width: 767px) {
    font-size: 36px !important;
    line-height: 100% !important;
    font-weight: 700 !important;
    /* or 40px */

    text-align: center;
    letter-spacing: -0.01em;
    max-width: 251px;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    max-width: 300px;
  }
  @media (min-width: 1200px) {
    max-width: 395px;
  }
}
.customH2Jp {
  margin: auto;
  @media (max-width: 767px) {
    font-size: 40px !important;
    line-height: 100% !important;
    font-weight: 700 !important;
    /* or 40px */

    text-align: center;
    letter-spacing: -0.01em;
    max-width: 290px;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    max-width: 400px;
  }
  @media (min-width: 1200px) {
    max-width: 400px;
  }
}
h4 {
  @media (max-width: 767px) {
    max-width: 218px;
    margin: 16px auto 18px auto !important;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin: 16px auto 18px auto !important;
  }
  @media (min-width: 1200px) {
    margin: 24px auto 64px auto !important;
  }
}
.container {
  max-width: 615px;
  padding: 0;
}
.row {
  margin: 0;
}
.main-form {
  max-width: 546px;
  margin: 0 auto;
}
.contact-form {
  @media (max-width: 767px) {
    margin-top: -300px;
    margin-bottom: 110px;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin-top: -270px;
    margin-bottom: 47px;
  }
  @media (min-width: 1200px) {
    margin-top: -40%;
    margin-bottom: 80px;
  }
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    /* transform: translateY(-300px); */
    // max-width: 546px;
  }
  /* transform: translateY(-450px); */
  // max-width: 615px;
  .contact-buttons {
    @media (max-width: 767px) {
      gap: 24px !important;
    }

    @media (max-width: 1199px) and (min-width: 768px) {
      gap: 20px !important;
    }
    @media (min-width: 1200px) {
      gap: 20px !important;
      margin-bottom: 32px;
    }
    .contact-button {
      background: #fcfcfd;
      color: inherit;
      border: 2px solid rgba(57, 69, 76, 0.3);
      box-sizing: border-box;
      border-radius: 8px;
      padding: 22px 0;
      &.active,
      &:hover {
        border: 2px solid var(--primary) !important;
        background: #fcfcfd;
        color: inherit;
        box-shadow: none !important;
      }
    }
  }
  .form-group {
    .col-form-label {
      text-transform: uppercase !important;
      color: #888f94;
    }
    .form-control {
      border-radius: 12px;
    }
  }
}
.button-text {
  //font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  /* Light Theme/Title Color */

  color: #39454c;
  margin: 0 !important;
  white-space: nowrap;
}

form {
  max-width: 546px;
  margin: auto;
}

.title {
  text-align: center;
}

label {
  margin-bottom: 12px !important;
}
.form-group {
  margin: 0;
}

label[for="attachment"] {
  cursor: pointer;
  color: #39454c;

  /* Style as you please, it will become the visible UI component. */
}

#attachment {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.selectType {
  //font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  color: #484c57;
}
.mb-32px {
  margin-bottom: 32px;
}
::v-deep input:not(.vs__search),
textarea {
  outline: none !important;
  background: #fcfcfd;

  box-sizing: border-box;
  border-radius: 12px;
  color: #777e90;
  width: 100%;

  &:focus {
    border-color: #b1b5c3 !important;
  }
}
::v-deep .maz-select__options-list__items {
  overflow-y: scroll;
  border-bottom: 16px solid #ffffff;
  border-right: 9px solid #ffffff;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #07b53b;
    height: 15px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: #9ce1b1;
    width: 5px !important;
  }
}
</style>
