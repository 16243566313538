<template>
  <div>
    <Hero />
    <ContactForm />
    <OurOffices />
  </div>
</template>

<script>
import Hero from "./components/sections/Hero.vue";
import ContactForm from "./components/sections/ContactForm.vue";
import OurOffices from "./components/sections/OurOffices.vue";
export default {
  components: {
    Hero,
    ContactForm,
    OurOffices
  },
  metaInfo: {
    title: 'NOZAX: Contact us',
    meta: [
      { name: 'description', content: "Have a question? Our support team is there for you 24/5. Send us your query, and we'll do our best to assist you!" },
    ]
  }
};
</script>
