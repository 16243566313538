var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "our-offices"
  }, [_c('div', {
    staticClass: "d-none d-md-block"
  }, [_c('p', {
    staticClass: "our-offices__title"
  }, [_vm._v("Our Offices")]), _c('div', {
    staticClass: "text-center"
  }), _c('div', {
    staticClass: "our-offices__body "
  }, [_c('div', {
    staticClass: "our-offices__map "
  }, [_vm.entity.includes('sv') ? _c('WorldMapPC', {
    staticClass: "our-offices__map--world d-none d-lg-block"
  }) : _vm._e(), _vm.entity.includes('jsc') ? _c('ADOfficesMapPC', {
    staticClass: "our-offices__map--world d-none d-lg-block"
  }) : _vm._e(), _vm.entity.includes('sv') ? _c('WorldMapTablet', {
    staticClass: "our-offices__map--world d-none d-md-block d-lg-none"
  }) : _vm._e(), _vm.entity.includes('jsc') ? _c('ADOfficesMapTablet', {
    staticClass: "our-offices__map--world d-none d-md-block d-lg-none"
  }) : _vm._e(), _c('b-button', {
    staticClass: "tooltip-montenegro border-transparent",
    attrs: {
      "id": "tooltip-montenegro"
    }
  }), _vm.entity.includes('sv') ? _c('b-button', {
    staticClass: "tooltip-myanmar border-transparent",
    attrs: {
      "id": "tooltip-myanmar"
    }
  }) : _vm._e(), _vm.entity.includes('sv') ? _c('b-button', {
    staticClass: "tooltip-laos border-transparent",
    attrs: {
      "id": "tooltip-laos"
    }
  }) : _vm._e(), _vm.entity.includes('sv') ? _c('b-button', {
    staticClass: "tooltip-philippines border-transparent",
    attrs: {
      "id": "tooltip-philippines"
    }
  }) : _vm._e(), _vm.entity.includes('sv') ? _c('b-button', {
    staticClass: "tooltip-indonesia border-transparent",
    attrs: {
      "id": "tooltip-indonesia"
    }
  }) : _vm._e(), _vm._l(_vm.offices, function (office) {
    return _c('div', [_c('b-tooltip', {
      attrs: {
        "target": ("tooltip-" + (office.name))
      }
    }, [_c('div', {
      staticClass: "tooltip-content"
    }, [_c('p', {
      staticClass: "our-offices__tooltip--title "
    }, [_vm._v(_vm._s(office.title))]), _c('div', {
      staticClass: "our-offices__tooltip--body"
    }, [_c('p', [_vm._v(" " + _vm._s(office.office_name) + " ")]), office.email ? _c('p', [_c('span', [_vm._v("Email: ")]), _c('span', [_vm._v(_vm._s(office.email))])]) : _vm._e(), office.address ? _c('p', [_c('span', [_vm._v("Address: ")]), _c('span', [_vm._v(_vm._s(office.address))])]) : _vm._e(), office.phone ? _c('p', [_c('span', [_vm._v("Phone: ")]), _c('span', [_vm._v(_vm._s(office.phone))])]) : _vm._e()])])])], 1);
  })], 2)])]), _c('div', {
    staticClass: "d-none d-sm-block d-md-none "
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center pb-5 "
  }, [_c('div', {
    staticClass: "office-map-mobile"
  }, [_vm.entity.includes('sv') ? _c('WorldMapMobile') : _vm._e(), _vm.entity.includes('jsc') ? _c('ADOfficesMapMobile') : _vm._e(), _vm.entity.includes('sv') ? _c('div', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-laos",
      modifiers: {
        "modal-laos": true
      }
    }],
    staticClass: "info-laos"
  }) : _vm._e(), _vm.entity.includes('sv') ? _c('div', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-montenegro",
      modifiers: {
        "modal-montenegro": true
      }
    }],
    staticClass: "info-montenegro"
  }) : _vm._e(), _vm.entity.includes('sv') ? _c('div', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-myanmar",
      modifiers: {
        "modal-myanmar": true
      }
    }],
    staticClass: "info-myanmar"
  }) : _vm._e(), _vm.entity.includes('sv') ? _c('div', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-indonesia",
      modifiers: {
        "modal-indonesia": true
      }
    }],
    staticClass: "info-indonesia"
  }) : _vm._e(), _vm.entity.includes('sv') ? _c('div', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-philippines",
      modifiers: {
        "modal-philippines": true
      }
    }],
    staticClass: "info-philippines"
  }) : _vm._e(), _vm.entity.includes('jsc') ? _c('div', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-montenegro",
      modifiers: {
        "modal-montenegro": true
      }
    }],
    staticClass: "info-montenegro__jsc"
  }) : _vm._e(), _vm._l(_vm.offices, function (office) {
    return _c('div', {
      staticClass: "office-modal"
    }, [_c('b-modal', {
      staticClass: "modal-montenegro",
      attrs: {
        "centered": "",
        "id": ("modal-" + (office.name)),
        "size": "md",
        "hide-header": true,
        "hide-footer": true,
        "no-close-on-backdrop": false,
        "no-close-on-esc": true
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center justify-content-between mb-4",
      on: {
        "click": function ($event) {
          return _vm.$bvModal.hide(("modal-" + (office.name)));
        }
      }
    }, [office.title ? _c('p', {
      staticClass: "mb-0 office-modal__header"
    }, [_vm._v(_vm._s(office.title))]) : _vm._e(), _c('CloseForm')], 1), _c('div', {
      staticClass: "office-modal__body"
    }, [office.office_name ? _c('p', {
      staticClass: "office-modal__content"
    }, [_vm._v(_vm._s(office.office_name))]) : _vm._e(), office.email ? _c('div', [_c('p', {
      staticClass: "mb-0 office-modal__title"
    }, [_vm._v("Email:")]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('p', {
      staticClass: "office-modal__content mr-2"
    }, [_vm._v(_vm._s(office.email))]), _c('span', {
      directives: [{
        name: "clipboard",
        rawName: "v-clipboard:copy",
        value: office.email,
        expression: "office.email",
        arg: "copy"
      }],
      staticClass: "pointer",
      on: {
        "click": function ($event) {
          return _vm.onCopy();
        }
      }
    }, [_c('ContentCopy')], 1)])]) : _vm._e(), office.address ? _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('p', {
      staticClass: "mb-0 office-modal__title mr-2"
    }, [_vm._v("Address:")]), _c('p', {
      staticClass: "mb-0 office-modal__content"
    }, [_vm._v(_vm._s(office.address))])]) : _vm._e(), office.phone ? _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('p', {
      staticClass: "mb-0 office-modal__title mr-2"
    }, [_vm._v("Phone:")]), _c('p', {
      staticClass: "mb-0 office-modal__content"
    }, [_vm._v(_vm._s(office.phone))])]) : _vm._e()])])], 1);
  })], 2)]), _c('p', {
    staticClass: "our-offices__title"
  }, [_vm._v("Our Offices")]), _c('div', {
    staticClass: "d-none d-sm-block d-md-none"
  }, [_vm.entity.includes('jsc') ? _c('div', {
    staticClass: "our-offices__tooltip"
  }, [_c('div', [_c('p', {
    staticClass: "our-offices__tooltip--title text-capitalize"
  }, [_vm._v(" " + _vm._s(_vm.offices.montenegro.name) + " ")]), _c('div', {
    staticClass: "our-offices__tooltip--body"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.offices.montenegro.office_name) + " ")]), _vm.offices.montenegro.email ? _c('p', [_c('span', [_vm._v("Email: ")]), _c('span', [_vm._v(_vm._s(_vm.offices.montenegro.email))])]) : _vm._e(), _vm.offices.montenegro.address ? _c('p', [_c('span', [_vm._v("Address: ")]), _c('span', [_vm._v(_vm._s(_vm.offices.montenegro.address))])]) : _vm._e(), _vm.offices.montenegro.phone ? _c('p', [_c('span', [_vm._v("Phone: ")]), _c('span', [_vm._v(_vm._s(_vm.offices.montenegro.phone))])]) : _vm._e()])])]) : _vm._e(), _vm.entity.includes('sv') ? _c('div', {
    staticClass: "our-offices__tooltip"
  }, _vm._l(_vm.offices, function (office) {
    return _c('div', [_c('div', {
      staticClass: "tooltip-content"
    }, [_c('p', {
      staticClass: "our-offices__tooltip--title text-uppercase"
    }, [_vm._v(_vm._s(office.title))]), _c('div', {
      staticClass: "our-offices__tooltip--body"
    }, [_c('p', [_vm._v(" " + _vm._s(office.office_name) + " ")]), office.email ? _c('p', [_c('strong', [_vm._v("Email: ")]), _c('span', [_vm._v(_vm._s(office.email))])]) : _vm._e(), office.address ? _c('p', [_c('strong', [_vm._v("Address: ")]), _c('span', [_vm._v(_vm._s(office.address))])]) : _vm._e(), office.phone ? _c('p', [_c('strong', [_vm._v("Phone: ")]), _c('span', [_vm._v(_vm._s(office.phone))])]) : _vm._e()])])]);
  }), 0) : _vm._e()])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }