var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "93",
      "height": "94",
      "viewBox": "0 0 93 94",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M62.1313 8.01953H80.1694C87.2536 8.01953 92.9965 13.7624 92.9965 20.8466V65.7415C92.9965 72.8257 87.2536 78.5686 80.1694 78.5686H57.7219L49.208 91.5239C48.6153 92.446 47.5944 93.0034 46.4982 93.0034C45.4021 93.0034 44.3812 92.446 43.7885 91.5239L35.2745 78.5686H12.8271C5.74289 78.5686 0 72.8257 0 65.7415V20.8466C0 13.7624 5.74289 8.01953 12.8271 8.01953H62.1313Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M6.41198 22.4445C5.52645 22.4445 4.80859 21.7267 4.80859 20.8412C4.81389 16.4157 8.4001 12.8295 12.8255 12.8242C13.7111 12.8242 14.4289 13.5421 14.4289 14.4276C14.4289 15.3131 13.7111 16.031 12.8255 16.031C10.1689 16.031 8.01537 18.1846 8.01537 20.8412C8.01537 21.2664 7.84644 21.6742 7.54575 21.9749C7.24505 22.2756 6.83723 22.4445 6.41198 22.4445Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M80.1659 73.757C79.2804 73.757 78.5625 73.0392 78.5625 72.1537C78.5625 71.2681 79.2804 70.5503 80.1659 70.5503C82.8225 70.5503 84.976 68.3967 84.976 65.7401C84.976 64.8546 85.6939 64.1367 86.5794 64.1367C87.465 64.1367 88.1828 64.8546 88.1828 65.7401C88.1775 70.1655 84.5913 73.7517 80.1659 73.757Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M30.4698 49.7029H20.8495C19.964 49.7029 19.2461 48.985 19.2461 48.0995C19.2461 47.214 19.964 46.4961 20.8495 46.4961H30.4698C31.3553 46.4961 32.0732 47.214 32.0732 48.0995C32.0732 48.985 31.3553 49.7029 30.4698 49.7029Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M72.1552 49.7029H36.8807C35.9952 49.7029 35.2773 48.985 35.2773 48.0995C35.2773 47.214 35.9952 46.4961 36.8807 46.4961H72.1552C73.0408 46.4961 73.7586 47.214 73.7586 48.0995C73.7586 48.985 73.0408 49.7029 72.1552 49.7029Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M72.1579 62.5271H20.8495C19.964 62.5271 19.2461 61.8092 19.2461 60.9237C19.2461 60.0382 19.964 59.3203 20.8495 59.3203H72.1579C73.0434 59.3203 73.7613 60.0382 73.7613 60.9237C73.7613 61.8092 73.0434 62.5271 72.1579 62.5271Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M46.4985 38.4813C57.1248 38.4813 65.7391 29.867 65.7391 19.2406C65.7391 8.61433 57.1248 0 46.4985 0C35.8721 0 27.2578 8.61433 27.2578 19.2406C27.2578 29.867 35.8721 38.4813 46.4985 38.4813Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M41.6886 30.4631C41.2634 30.463 40.8557 30.2941 40.555 29.9933L34.1415 23.5798C33.5339 22.9507 33.5425 21.9506 34.161 21.3321C34.7795 20.7137 35.7796 20.705 36.4087 21.3126L41.6886 26.5926L56.5889 11.6923C57.218 11.0846 58.2181 11.0933 58.8366 11.7118C59.4551 12.3303 59.4637 13.3303 58.8561 13.9595L42.8222 29.9933C42.5216 30.2941 42.1138 30.463 41.6886 30.4631Z",
      "fill": "white"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }