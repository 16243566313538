var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "1154",
      "height": "679",
      "viewBox": "0 0 1154 679",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "617.546",
      "cy": "129.546",
      "r": "45.0459",
      "stroke": "#ED6400",
      "stroke-width": "3"
    }
  }), _c('path', {
    attrs: {
      "d": "M647.777 164.262L668.755 185.239",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M663 180L680 196",
      "stroke": "#ED6400",
      "stroke-width": "8",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M653.039 245V248.4",
      "stroke": "#07B53B",
      "stroke-width": "1.49386",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M653.039 245V248.4",
      "stroke": "#07B53B",
      "stroke-width": "1.49386",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M646.02 246.879L647.722 249.823",
      "stroke": "#07B53B",
      "stroke-width": "1.49386",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M640.879 252.02L643.823 253.722",
      "stroke": "#07B53B",
      "stroke-width": "1.49386",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M639 259.039H642.4",
      "stroke": "#07B53B",
      "stroke-width": "1.49386",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M640.879 266.066L643.823 264.363",
      "stroke": "#07B53B",
      "stroke-width": "1.49386",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M646.02 271.206L647.722 268.262",
      "stroke": "#07B53B",
      "stroke-width": "1.49386",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M653.039 273.083V269.684",
      "stroke": "#07B53B",
      "stroke-width": "1.49386",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M660.062 271.206L658.359 268.262",
      "stroke": "#07B53B",
      "stroke-width": "1.49386",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M665.202 266.066L662.258 264.363",
      "stroke": "#07B53B",
      "stroke-width": "1.49386",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M667.083 259.039H663.684",
      "stroke": "#07B53B",
      "stroke-width": "1.49386",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M665.202 252.02L662.258 253.722",
      "stroke": "#07B53B",
      "stroke-width": "1.49386",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M660.062 246.879L658.359 249.823",
      "stroke": "#07B53B",
      "stroke-width": "1.49386",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M660.835 259.043C660.835 263.346 657.346 266.835 653.043 266.835C648.739 266.835 645.25 263.346 645.25 259.043C645.25 254.739 648.739 251.25 653.043 251.25C657.346 251.25 660.835 254.739 660.835 259.043Z",
      "stroke": "#ED6400",
      "stroke-width": "2",
      "stroke-miterlimit": "10"
    }
  }), _c('path', {
    attrs: {
      "d": "M686.507 286.999H636.562C636.562 280.597 640.807 275.153 646.718 273.161C648.196 266.572 654.257 261.637 661.511 261.637C668.754 261.637 674.809 266.562 676.292 273.14C682.235 275.116 686.507 280.577 686.507 286.999Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M657.412 274.096H634C634 271.043 635.99 268.446 638.76 267.496C639.453 264.354 642.294 262 645.694 262C649.09 262 651.928 264.349 652.623 267.486C655.409 268.429 657.412 271.033 657.412 274.096Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M412.954 261H399.327L391 279.662H401.599L394.028 297.577L416.739 272.197H406.141L412.954 261Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M422.054 135.738C429.786 135.738 436.055 142.007 436.055 149.739V210.232C436.055 217.964 429.786 224.233 422.054 224.233H415.254V241.63L399.707 224.233H295.001C287.269 224.233 281 217.964 281 210.232V149.739C281 142.007 287.269 135.738 295.001 135.738H422.054Z",
      "fill": "#07B53B"
    }
  }), _c('mask', {
    attrs: {
      "id": "path-22-inside-1_2002_42583",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M401.722 110C409.454 110 415.723 116.268 415.723 124.001V150.489C415.723 157.818 410.091 163.832 402.918 164.439V175.202L393.346 164.489H334.251C326.518 164.489 320.25 158.221 320.25 150.489V124.001C320.25 116.268 326.518 110 334.251 110H401.722Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M401.722 110C409.454 110 415.723 116.268 415.723 124.001V150.489C415.723 157.818 410.091 163.832 402.918 164.439V175.202L393.346 164.489H334.251C326.518 164.489 320.25 158.221 320.25 150.489V124.001C320.25 116.268 326.518 110 334.251 110H401.722Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M402.918 164.439H400.886V162.572L402.747 162.414L402.918 164.439ZM402.918 175.202H404.95V180.525L401.403 176.556L402.918 175.202ZM393.346 164.489V162.457H394.255L394.861 163.135L393.346 164.489ZM413.691 124.001C413.691 117.391 408.332 112.032 401.722 112.032V107.968C410.577 107.968 417.755 115.146 417.755 124.001H413.691ZM413.691 150.489V124.001H417.755V150.489H413.691ZM402.747 162.414C408.876 161.895 413.691 156.753 413.691 150.489H417.755C417.755 158.883 411.305 165.768 403.09 166.464L402.747 162.414ZM404.95 164.439V175.202H400.886V164.439H404.95ZM401.403 176.556L391.831 165.843L394.861 163.135L404.434 173.847L401.403 176.556ZM334.251 162.457H393.346V166.521H334.251V162.457ZM322.282 150.489C322.282 157.099 327.641 162.457 334.251 162.457V166.521C325.396 166.521 318.218 159.343 318.218 150.489H322.282ZM322.282 124.001V150.489H318.218V124.001H322.282ZM334.251 112.032C327.641 112.032 322.282 117.391 322.282 124.001H318.218C318.218 115.146 325.396 107.968 334.251 107.968V112.032ZM401.722 112.032H334.251V107.968H401.722V112.032Z",
      "fill": "#ED6400",
      "mask": "url(#path-22-inside-1_2002_42583)"
    }
  }), _c('rect', {
    attrs: {
      "x": "331.785",
      "y": "124.906",
      "width": "54.1883",
      "height": "7.45089",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "331.785",
      "y": "138.453",
      "width": "34.545",
      "height": "7.45089",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M434.985 66H370C370 57.6705 375.523 50.586 383.214 47.9945C385.136 39.4214 393.023 33 402.461 33C411.885 33 419.764 39.4078 421.694 47.9674C429.426 50.5387 434.985 57.6434 434.985 66Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M440.567 49.2462H408.582C408.582 45.1455 411.3 41.6577 415.086 40.3819C416.032 36.1613 419.913 33 424.559 33C429.197 33 433.075 36.1546 434.025 40.3686C437.831 41.6344 440.567 45.1322 440.567 49.2462Z",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "r": "3.38677",
      "transform": "matrix(-1 0 0 1 576.569 28.0915)",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.35471"
    }
  }), _c('circle', {
    attrs: {
      "r": "3.38677",
      "transform": "matrix(-1 0 0 1 499.065 175.064)",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.35471"
    }
  }), _c('circle', {
    attrs: {
      "r": "3.38677",
      "transform": "matrix(-1 0 0 1 707.065 142.064)",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.35471"
    }
  }), _c('path', {
    attrs: {
      "d": "M442.539 3.51172L437.51 8.54081",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M437.516 3.51172L442.545 8.54081",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M462.539 281.512L457.51 286.541",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M457.516 281.512L462.545 286.541",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M607 191V201.16",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M616.484 196.418L610.388 202.514",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M621.902 205.902L611.742 205.902",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M79.4844 81C58.7475 168.236 53.2399 350.72 197.104 382.767C376.935 422.826 460.324 261.841 616.536 325.405C772.749 388.969 821.638 634.772 951.98 589.247C1056.25 552.826 1101.63 560.689 1111.29 569.173",
      "stroke": "#07B53B",
      "stroke-width": "2.03206",
      "stroke-linecap": "round",
      "stroke-dasharray": "4.06 4.06"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M655.689 6.60547C652.547 6.60547 650 9.15237 650 12.2941V36.8729C650 40.0147 652.547 42.5616 655.689 42.5616H658.453V49.6321L664.771 42.5616H707.311C710.453 42.5616 713 40.0147 713 36.8729V12.2941C713 9.15237 710.453 6.60547 707.311 6.60547H655.689Z",
      "fill": "#07B53B"
    }
  }), _c('mask', {
    attrs: {
      "id": "path-40-inside-2_2002_42583",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M674.13 0C670.988 0 668.441 2.54689 668.441 5.68865V16.4509C668.441 19.4291 670.73 21.8727 673.645 22.1191V26.4937L677.536 22.1395H701.544C704.686 22.1395 707.233 19.5926 707.233 16.4509V5.68865C707.233 2.5469 704.686 0 701.544 0H674.13Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M674.13 0C670.988 0 668.441 2.54689 668.441 5.68865V16.4509C668.441 19.4291 670.73 21.8727 673.645 22.1191V26.4937L677.536 22.1395H701.544C704.686 22.1395 707.233 19.5926 707.233 16.4509V5.68865C707.233 2.5469 704.686 0 701.544 0H674.13Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M673.645 22.1191H674.47V21.3603L673.714 21.2964L673.645 22.1191ZM673.645 26.4937H672.819V28.6567L674.26 27.0438L673.645 26.4937ZM677.536 22.1395V21.3139H677.166L676.92 21.5894L677.536 22.1395ZM669.267 5.68865C669.267 3.00288 671.444 0.825644 674.13 0.825644V-0.825644C670.532 -0.825644 667.616 2.0909 667.616 5.68865H669.267ZM669.267 16.4509V5.68865H667.616V16.4509H669.267ZM673.714 21.2964C671.223 21.0858 669.267 18.9964 669.267 16.4509H667.616C667.616 19.8617 670.237 22.6596 673.575 22.9418L673.714 21.2964ZM672.819 22.1191V26.4937H674.47V22.1191H672.819ZM674.26 27.0438L678.151 22.6897L676.92 21.5894L673.029 25.9435L674.26 27.0438ZM701.544 21.3139H677.536V22.9651H701.544V21.3139ZM706.407 16.4509C706.407 19.1366 704.23 21.3139 701.544 21.3139V22.9651C705.142 22.9651 708.058 20.0486 708.058 16.4509H706.407ZM706.407 5.68865V16.4509H708.058V5.68865H706.407ZM701.544 0.825644C704.23 0.825644 706.407 3.00289 706.407 5.68865H708.058C708.058 2.0909 705.142 -0.825644 701.544 -0.825644V0.825644ZM674.13 0.825644H701.544V-0.825644H674.13V0.825644Z",
      "fill": "#ED6400",
      "mask": "url(#path-40-inside-2_2002_42583)"
    }
  }), _c('rect', {
    attrs: {
      "x": "675.594",
      "y": "8.53125",
      "width": "22.0172",
      "height": "3.02736",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "675.594",
      "y": "14.0352",
      "width": "14.036",
      "height": "3.02736",
      "fill": "#ED6400"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }