<template>
  <div class="our-offices">
    <!-- PC and Tablet -->
    <div class="d-none d-md-block">
      <p class="our-offices__title">Our Offices</p>
      <div class="text-center">

      </div>
      <div class="our-offices__body ">
        <div class="our-offices__map ">
          <!-- PC map -->
          <WorldMapPC class="our-offices__map--world d-none d-lg-block" v-if="entity.includes('sv')" />
          <ADOfficesMapPC class="our-offices__map--world d-none d-lg-block" v-if="entity.includes('jsc')" />

          <!-- Tablet map -->
          <WorldMapTablet class="our-offices__map--world d-none d-md-block d-lg-none" v-if="entity.includes('sv')" />
          <ADOfficesMapTablet class="our-offices__map--world d-none d-md-block d-lg-none" v-if="entity.includes('jsc')" />

          <!-- Tooltip Montenegro -->
          <b-button class="tooltip-montenegro border-transparent" id="tooltip-montenegro"> </b-button>
          <b-button class="tooltip-myanmar border-transparent" v-if="entity.includes('sv')" id="tooltip-myanmar">
          </b-button>
          <b-button class="tooltip-laos border-transparent" v-if="entity.includes('sv')" id="tooltip-laos"> </b-button>
          <b-button class="tooltip-philippines border-transparent" v-if="entity.includes('sv')" id="tooltip-philippines">
          </b-button>
          <b-button class="tooltip-indonesia border-transparent" v-if="entity.includes('sv')" id="tooltip-indonesia">
          </b-button>

          <div v-for="office in offices">
            <b-tooltip :target="`tooltip-${office.name}`">
              <div class="tooltip-content">
                <p class="our-offices__tooltip--title ">{{ office.title }}</p>
                <div class="our-offices__tooltip--body">
                  <p> {{ office.office_name }} </p>
                  <p v-if='office.email'>
                    <span>Email: </span>
                    <span>{{ office.email }}</span>
                  </p>
                  <p v-if='office.address'>
                    <span>Address: </span>
                    <span>{{ office.address }}</span>
                  </p>
                  <p v-if='office.phone'>
                    <span>Phone: </span>
                    <span>{{ office.phone }}</span>
                  </p>
                </div>
              </div>
            </b-tooltip>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile -->
    <div class="d-none d-sm-block d-md-none ">
      <div class="d-flex align-items-center justify-content-center pb-5 ">
        <div class="office-map-mobile">
          <!-- Mobile map -->
          <WorldMapMobile v-if="entity.includes('sv')" />
          <ADOfficesMapMobile v-if="entity.includes('jsc')" />

          <div class="info-laos" v-b-modal.modal-laos v-if="entity.includes('sv')"> </div>
          <div class="info-montenegro" v-b-modal.modal-montenegro v-if="entity.includes('sv')"> </div>
          <div class="info-myanmar" v-b-modal.modal-myanmar v-if="entity.includes('sv')"> </div>
          <div class="info-indonesia" v-b-modal.modal-indonesia v-if="entity.includes('sv')"> </div>
          <div class="info-philippines" v-b-modal.modal-philippines v-if="entity.includes('sv')"> </div>

          <div class="info-montenegro__jsc" v-b-modal.modal-montenegro v-if="entity.includes('jsc')"> </div>


          <div v-for="office in offices" class="office-modal">
            <b-modal centered :id="`modal-${office.name}`" size="md" :hide-header="true" :hide-footer="true"
              :no-close-on-backdrop="false" :no-close-on-esc="true" class="modal-montenegro">
              <div @click="$bvModal.hide(`modal-${office.name}`)"
                class="d-flex align-items-center justify-content-between mb-4">
                <p class="mb-0 office-modal__header" v-if='office.title'>{{ office.title }}</p>
                <CloseForm />
              </div>
              <div class="office-modal__body">
                <p class="office-modal__content" v-if='office.office_name'>{{ office.office_name }}</p>
                <div v-if='office.email'>
                  <p class="mb-0 office-modal__title">Email:</p>
                  <div class="d-flex align-items-center">
                    <p class="office-modal__content mr-2">{{ office.email }}</p>
                    <span v-clipboard:copy="office.email" @click="onCopy()" class="pointer">
                      <ContentCopy />
                    </span>
                  </div>
                </div>
                <div class="d-flex align-items-center" v-if='office.address'>
                  <p class="mb-0 office-modal__title mr-2">Address:</p>
                  <p class="mb-0 office-modal__content">{{ office.address }}</p>
                </div>
                <div class="d-flex align-items-center" v-if='office.phone'>
                  <p class="mb-0 office-modal__title mr-2">Phone:</p>
                  <p class="mb-0 office-modal__content">{{ office.phone }}</p>
                </div>
              </div>
            </b-modal>
          </div>
        </div>
      </div>
      <p class="our-offices__title">Our Offices</p>
      <div class="d-none d-sm-block d-md-none">
        <div v-if="entity.includes('jsc')" class="our-offices__tooltip">
          <div>
            <p class="our-offices__tooltip--title text-capitalize"> {{ offices.montenegro.name }} </p>
            <div class="our-offices__tooltip--body">
              <p> {{ offices.montenegro.office_name }} </p>
              <p v-if='offices.montenegro.email'>
                <span>Email: </span>
                <span>{{ offices.montenegro.email }}</span>
              </p>
              <p v-if='offices.montenegro.address'>
                <span>Address: </span>
                <span>{{ offices.montenegro.address }}</span>
              </p>
              <p v-if='offices.montenegro.phone'>
                <span>Phone: </span>
                <span>{{ offices.montenegro.phone }}</span>
              </p>
            </div>
          </div>
        </div>

        <div v-if="entity.includes('sv')" class="our-offices__tooltip">
          <div v-for="office in offices">
            <div class="tooltip-content">
              <p class="our-offices__tooltip--title text-uppercase">{{ office.title }}</p>
              <div class="our-offices__tooltip--body">
                <p> {{ office.office_name }} </p>
                <p v-if="office.email">
                  <strong>Email: </strong>
                  <span>{{ office.email }}</span>
                </p>
                <p v-if="office.address">
                  <strong>Address: </strong>
                  <span>{{ office.address }}</span>
                </p>
                <p v-if="office.phone">
                  <strong>Phone: </strong>
                  <span>{{ office.phone }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WorldMapPC from '../svg/ourOffices/WorldMapPC';
import WorldMapTablet from '../svg/ourOffices/WorldMapTablet';
import WorldMapMobile from '../svg/ourOffices/WorldMapMobile';

import ADOfficesMapPC from '../svg/ourOffices/ADOfficesMapPC';
import ADOfficesMapTablet from '../svg/ourOffices/ADOfficesMapTablet';
import ADOfficesMapMobile from '../svg/ourOffices/ADOfficesMapMobile';

import ContentCopy from '../svg/ourOffices/ContentCopy';
import CloseForm from "../svg/contactForm/CLoseForm.vue";


export default {
  name: "OurOffices",
  components: {
    WorldMapPC,
    WorldMapTablet,
    WorldMapMobile,

    ADOfficesMapPC,
    ADOfficesMapTablet,
    ADOfficesMapMobile,

    ContentCopy,
    CloseForm,
  },
  data() {
    return {
      offices: {
        montenegro: {
          name: "montenegro",
          title: this.$t("our-offices.montenegro.title"),
          email: this.$t("our-offices.montenegro.email"),
          address: this.$t("our-offices.montenegro.address"),
          office_name: this.$t("our-offices.montenegro.office-name"),
          phone: this.$t("our-offices.montenegro.phone"),
        },
        myanmar: {
          name: "myanmar",
          title: this.$t("our-offices.myanmar.title"),
          email: this.$t("our-offices.myanmar.email"),
          address: this.$t("our-offices.myanmar.address"),
          office_name: this.$t("our-offices.myanmar.office-name"),
          phone: this.$t("our-offices.myanmar.phone"),
        },
        laos: {
          name: "laos",
          title: this.$t("our-offices.laos.title"),
          email: this.$t("our-offices.laos.email"),
          address: this.$t("our-offices.laos.address"),
          office_name: this.$t("our-offices.laos.office-name"),
          phone: this.$t("our-offices.laos.phone"),
        },
        philippines: {
          name: "philippines",
          title: this.$t("our-offices.philippines.title"),
          email: this.$t("our-offices.philippines.email"),
          address: this.$t("our-offices.philippines.address"),
          office_name: this.$t("our-offices.philippines.office-name"),
          phone: this.$t("our-offices.philippines.phone"),
        },
        indonesia: {
          name: "indonesia",
          title: this.$t("our-offices.indonesia.title"),
          email: this.$t("our-offices.indonesia.email"),
          address: this.$t("our-offices.indonesia.address"),
          office_name: this.$t("our-offices.indonesia.office-name"),
          phone: this.$t("our-offices.indonesia.phone"),
        },
      },
    }
  },
  methods: {
    onCopy() {
      this.$bvToast.toast('Copied to clipboard!', {
        title: `Success`,
        variant: 'success',
      })
    },
  },
  computed: {
    entity() {
      return localStorage.getItem("APP_IDENTITY");
    },
  }

};
</script>
<style lang="scss" scoped>
.our-offices {
  position: relative;

  @media screen and (max-width: 767px) {
    padding: 0 32px;
  }

  .our-offices__title {
    font-size: 40px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 0;
    color: #39454C;
  }

  .our-offices__body {
    @media screen and (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 32px 0 64px;
    }
  }

  .our-offices__map {
    position: relative;
  }

  .our-offices__tooltip {
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      gap: 32px;
      padding: 24px 0 64px;
    }
  }

  // Tooltip Montenegro
  .tooltip-montenegro {
    background-color: transparent;
    position: absolute;
    padding: 0 !important;

    @media screen and (min-width: 1024px) {
      width: 19px;
      height: 52px;
      top: 14%;
      right: 44.8%;
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 13px;
      height: 32px;
      top: 15%;
      right: 44.8%;
    }
  }

  // Tooltip Myanmar
  .tooltip-myanmar {
    background-color: transparent;
    position: absolute;
    z-index: 1000;
    padding: 0 !important;

    @media screen and (min-width: 1024px) {
      width: 17px;
      height: 112px;
      top: 14%;
      right: 23.3%;
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 12px;
      height: 104px;
      top: 6%;
      right: 23.4%;
    }


  }

  // Tooltip Laos
  .tooltip-laos {
    background-color: transparent;
    position: absolute;
    z-index: 1000;
    padding: 0 !important;

    @media screen and (min-width: 1024px) {
      width: 17px;
      height: 70px;
      top: 26.5%;
      right: 21.2%;
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 12px;
      height: 58px;
      top: 23.5%;
      right: 21.2%;
    }
  }


  // Tooltip Philippines
  .tooltip-philippines {
    background-color: transparent;
    position: absolute;
    z-index: 1000;
    padding: 0 !important;

    @media screen and (min-width: 1024px) {
      width: 16px;
      height: 47px;
      top: 35.8%;
      right: 15.85%;
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 12px;
      height: 33px;
      top: 36%;
      right: 15.85%;
    }
  }

  // Tooltip Indonesia
  .tooltip-indonesia {
    background-color: transparent;
    position: absolute;
    z-index: 1000;
    padding: 0 !important;

    @media screen and (min-width: 1024px) {
      width: 17px;
      height: 41px;
      top: 53%;
      right: 19.3%;
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 12px;
      height: 26px;
      top: 53.8%;
      right: 19.2%;
    }
  }

  .office-map-mobile {
    position: relative;
  }

  .info-laos {
    background-color: transparent;

    height: 24px;
    width: 86px;
    border-radius: 20px;
    position: absolute;
    top: 1%;
    right: 29.5%;
    z-index: 1000;
  }

  .info-montenegro__jsc {
    background-color: transparent;
    height: 26px;
    width: 100px;
    border-radius: 20px;
    position: absolute;
    top: 0%;
    left: 17%;
    z-index: 1000;
  }


  .info-montenegro {
    background-color: transparent;
    height: 27px;
    width: 102px;
    border-radius: 20px;
    position: absolute;
    top: 20%;
    left: 14%;
    z-index: 1000;
  }

  .info-myanmar {
    background-color: transparent;
    height: 26px;
    width: 89px;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    right: 29%;
    z-index: 1000;
  }

  .info-indonesia {
    background-color: transparent;
    height: 26px;
    width: 90px;
    border-radius: 20px;
    position: absolute;
    top: 68%;
    right: 25%;
    z-index: 1000;
  }

  .info-philippines {
    background-color: transparent;
    height: 26px;
    width: 92px;
    border-radius: 20px;
    position: absolute;
    top: 89%;
    right: 22%;
    z-index: 1000;
  }
}

.office-modal__header {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: #484C57;
}

.office-modal__title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #484C57;
}

.office-modal__body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.office-modal__content {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #484C57;
  margin-bottom: 0px;
}
</style>