var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "1154",
      "height": "600",
      "viewBox": "0 0 1154 600",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "606.693",
      "cy": "122.611",
      "r": "66.9557",
      "stroke": "#ED6400",
      "stroke-width": "2.70941"
    }
  }), _c('path', {
    attrs: {
      "d": "M651.062 173.562L681.849 204.349",
      "stroke": "#ED6400",
      "stroke-width": "2.70941",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M674.902 197.395L705.689 228.181",
      "stroke": "#ED6400",
      "stroke-width": "10.1603",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M523.477 159.293V169.453",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M532.961 164.711L526.865 170.807",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M538.379 174.195L528.219 174.195",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M192.252 278.008C192.252 279.879 193.769 281.396 195.639 281.396C197.509 281.396 199.026 279.879 199.026 278.008C199.026 276.136 197.509 274.619 195.639 274.619C193.769 274.619 192.252 276.136 192.252 278.008Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.35471"
    }
  }), _c('path', {
    attrs: {
      "d": "M316.873 40.1404C316.873 42.0123 318.39 43.5292 320.26 43.5292C322.13 43.5292 323.647 42.0123 323.647 40.1404C323.647 38.2685 322.13 36.7516 320.26 36.7516C318.39 36.7516 316.873 38.2685 316.873 40.1404Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.35471"
    }
  }), _c('path', {
    attrs: {
      "d": "M866.205 365.433C866.205 367.305 867.722 368.822 869.592 368.822C871.462 368.822 872.979 367.305 872.979 365.433C872.979 363.561 871.462 362.045 869.592 362.045C867.722 362.045 866.205 363.561 866.205 365.433Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.35471"
    }
  }), _c('path', {
    attrs: {
      "d": "M781.537 346.453C781.537 348.325 783.054 349.842 784.924 349.842C786.794 349.842 788.311 348.325 788.311 346.453C788.311 344.581 786.794 343.064 784.924 343.064C783.054 343.064 781.537 344.581 781.537 346.453Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.35471"
    }
  }), _c('path', {
    attrs: {
      "d": "M362.932 194.656C362.932 196.528 364.449 198.045 366.319 198.045C368.189 198.045 369.705 196.528 369.705 194.656C369.705 192.784 368.189 191.267 366.319 191.267C364.449 191.267 362.932 192.784 362.932 194.656Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.35471"
    }
  }), _c('path', {
    attrs: {
      "d": "M820.918 250.031L815.889 255.063",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M815.895 250.031L820.924 255.063",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M480.879 69.0859L475.85 74.1176",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M475.855 69.0859L480.885 74.1176",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M415.176 247.996L410.147 253.028",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M410.152 247.996L415.181 253.028",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M610.258 210.727L605.229 215.758",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M605.234 210.727L610.263 215.758",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M886.613 266.973L881.584 272.004",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M881.59 266.973L886.619 272.004",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M306.805 83.9922L301.776 89.0238",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M301.781 83.9922L306.81 89.0238",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M130.388 63.1094C122.655 63.1094 116.387 69.3777 116.387 77.1102V137.603C116.387 145.336 122.655 151.604 130.388 151.604H137.188V169.001L152.734 151.604H257.44C265.173 151.604 271.441 145.336 271.441 137.603V77.1102C271.441 69.3778 265.173 63.1094 257.44 63.1094H130.388Z",
      "fill": "#07B53B"
    }
  }), _c('mask', {
    attrs: {
      "id": "path-25-inside-1_2002_42580",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M175.774 46.8516C168.042 46.8516 161.773 53.1199 161.773 60.8524V87.3401C161.773 94.6683 167.404 100.682 174.574 101.29V112.054L184.147 101.341H243.245C250.978 101.341 257.246 95.0725 257.246 87.3401V60.8524C257.246 53.1199 250.978 46.8516 243.245 46.8516H175.774Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M175.774 46.8516C168.042 46.8516 161.773 53.1199 161.773 60.8524V87.3401C161.773 94.6683 167.404 100.682 174.574 101.29V112.054L184.147 101.341H243.245C250.978 101.341 257.246 95.0725 257.246 87.3401V60.8524C257.246 53.1199 250.978 46.8516 243.245 46.8516H175.774Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M174.574 101.29H176.606V99.4233L174.746 99.2654L174.574 101.29ZM174.574 112.054H172.542V117.377L176.089 113.408L174.574 112.054ZM184.147 101.341V99.3089H183.238L182.632 99.9869L184.147 101.341ZM163.805 60.8524C163.805 54.2422 169.164 48.8836 175.774 48.8836V44.8195C166.92 44.8195 159.741 51.9977 159.741 60.8524H163.805ZM163.805 87.3401V60.8524H159.741V87.3401H163.805ZM174.746 99.2654C168.618 98.7453 163.805 93.6037 163.805 87.3401H159.741C159.741 95.733 166.189 102.618 174.402 103.315L174.746 99.2654ZM172.542 101.29V112.054H176.606V101.29H172.542ZM176.089 113.408L185.663 102.695L182.632 99.9869L173.059 110.7L176.089 113.408ZM243.245 99.3089H184.147V103.373H243.245V99.3089ZM255.214 87.3401C255.214 93.9503 249.856 99.3089 243.245 99.3089V103.373C252.1 103.373 259.278 96.1948 259.278 87.3401H255.214ZM255.214 60.8524V87.3401H259.278V60.8524H255.214ZM243.245 48.8836C249.856 48.8836 255.214 54.2422 255.214 60.8524H259.278C259.278 51.9977 252.1 44.8195 243.245 44.8195V48.8836ZM175.774 48.8836H243.245V44.8195H175.774V48.8836Z",
      "fill": "#ED6400",
      "mask": "url(#path-25-inside-1_2002_42580)"
    }
  }), _c('rect', {
    attrs: {
      "x": "179.383",
      "y": "67.8516",
      "width": "54.1883",
      "height": "7.45089",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "179.383",
      "y": "81.4023",
      "width": "34.545",
      "height": "7.45089",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M891.464 155.223C899.196 155.223 905.465 161.491 905.465 169.223V229.716C905.465 237.449 899.196 243.717 891.464 243.717H884.664V261.114L869.118 243.717H764.411C756.679 243.717 750.41 237.449 750.41 229.716V169.223C750.41 161.491 756.679 155.223 764.411 155.223H891.464Z",
      "fill": "#07B53B"
    }
  }), _c('mask', {
    attrs: {
      "id": "path-30-inside-2_2002_42580",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M871.132 129.484C878.864 129.484 885.133 135.753 885.133 143.485V169.973C885.133 177.302 879.501 183.316 872.328 183.923V194.686L862.756 183.974H803.661C795.928 183.974 789.66 177.705 789.66 169.973V143.485C789.66 135.753 795.928 129.484 803.661 129.484H871.132Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M871.132 129.484C878.864 129.484 885.133 135.753 885.133 143.485V169.973C885.133 177.302 879.501 183.316 872.328 183.923V194.686L862.756 183.974H803.661C795.928 183.974 789.66 177.705 789.66 169.973V143.485C789.66 135.753 795.928 129.484 803.661 129.484H871.132Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M872.328 183.923H870.296V182.056L872.157 181.898L872.328 183.923ZM872.328 194.686H874.361V200.009L870.813 196.04L872.328 194.686ZM862.756 183.974V181.942H863.665L864.271 182.62L862.756 183.974ZM883.101 143.485C883.101 136.875 877.742 131.516 871.132 131.516V127.452C879.987 127.452 887.165 134.63 887.165 143.485H883.101ZM883.101 169.973V143.485H887.165V169.973H883.101ZM872.157 181.898C878.286 181.38 883.101 176.238 883.101 169.973H887.165C887.165 178.367 880.715 185.253 872.5 185.948L872.157 181.898ZM874.361 183.923V194.686H870.296V183.923H874.361ZM870.813 196.04L861.241 185.328L864.271 182.62L873.844 193.332L870.813 196.04ZM803.661 181.942H862.756V186.006H803.661V181.942ZM791.692 169.973C791.692 176.583 797.051 181.942 803.661 181.942V186.006C794.806 186.006 787.628 178.828 787.628 169.973H791.692ZM791.692 143.485V169.973H787.628V143.485H791.692ZM803.661 131.516C797.051 131.516 791.692 136.875 791.692 143.485H787.628C787.628 134.63 794.806 127.452 803.661 127.452V131.516ZM871.132 131.516H803.661V127.452H871.132V131.516Z",
      "fill": "#ED6400",
      "mask": "url(#path-30-inside-2_2002_42580)"
    }
  }), _c('rect', {
    attrs: {
      "x": "801.195",
      "y": "144.391",
      "width": "54.1883",
      "height": "7.45089",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "801.195",
      "y": "157.938",
      "width": "34.545",
      "height": "7.45089",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M79.4844 2C58.7475 89.2362 53.2399 271.72 197.104 303.767C376.935 343.826 460.324 182.841 616.536 246.405C772.749 309.969 821.638 555.772 951.98 510.247C1056.25 473.826 1101.63 481.689 1111.29 490.173",
      "stroke": "#07B53B",
      "stroke-width": "2.03206",
      "stroke-linecap": "round",
      "stroke-dasharray": "4.06 4.06"
    }
  }), _c('path', {
    attrs: {
      "d": "M250.812 188.555V192.567",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M250.812 188.555V192.567",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M242.527 190.773L244.535 194.248",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M236.469 196.836L239.941 198.845",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M234.25 205.125H238.26",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M236.469 213.415L239.941 211.406",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M242.527 219.482L244.535 216.008",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M250.812 221.704V217.691",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M259.098 219.482L257.09 216.008",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M265.16 213.415L261.688 211.406",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M267.382 205.125H263.371",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M265.16 196.836L261.688 198.845",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M259.098 190.773L257.09 194.248",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M250.81 215.505C256.538 215.505 261.182 210.859 261.182 205.128C261.182 199.396 256.538 194.75 250.81 194.75C245.081 194.75 240.438 199.396 240.438 205.128C240.438 210.859 245.081 215.505 250.81 215.505Z",
      "stroke": "#ED6400",
      "stroke-width": "0.677354",
      "stroke-miterlimit": "10"
    }
  }), _c('path', {
    attrs: {
      "d": "M278.279 244.124H191.578C191.578 233.006 198.947 223.549 209.208 220.09C211.773 208.646 222.294 200.074 234.887 200.074C247.461 200.074 257.973 208.628 260.547 220.053C270.864 223.486 278.279 232.97 278.279 244.124Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M285.728 221.76H243.055C243.055 216.287 246.682 211.631 251.732 209.928C252.994 204.294 258.173 200.074 264.371 200.074C270.559 200.074 275.733 204.285 277 209.91C282.078 211.6 285.728 216.269 285.728 221.76Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M848.91 326.797V330.809",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M848.91 326.797V330.809",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M840.625 329.016L842.633 332.49",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M834.566 335.082L838.039 337.091",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M832.348 343.367H836.358",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M834.566 351.661L838.039 349.652",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M840.625 357.724L842.633 354.25",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M848.91 359.942V355.93",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M857.196 357.724L855.188 354.25",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M863.258 351.661L859.785 349.652",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M865.479 343.367H861.469",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M863.258 335.082L859.785 337.091",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M857.196 329.016L855.188 332.49",
      "stroke": "#07B53B",
      "stroke-width": "1.35471",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M848.907 353.743C854.636 353.743 859.28 349.097 859.28 343.366C859.28 337.634 854.636 332.988 848.907 332.988C843.179 332.988 838.535 337.634 838.535 343.366C838.535 349.097 843.179 353.743 848.907 353.743Z",
      "stroke": "#ED6400",
      "stroke-width": "0.677354",
      "stroke-miterlimit": "10"
    }
  }), _c('path', {
    attrs: {
      "d": "M914.311 382.374H827.609C827.609 371.256 834.978 361.799 845.239 358.34C847.804 346.896 858.326 338.324 870.918 338.324C883.492 338.324 894.004 346.878 896.579 358.303C906.895 361.736 914.311 371.22 914.311 382.374Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M865.54 365.43H824.898C824.898 360.128 828.353 355.618 833.162 353.968C834.365 348.51 839.297 344.422 845.199 344.422C851.093 344.422 856.021 348.501 857.228 353.95C862.064 355.587 865.54 360.11 865.54 365.43Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M548.001 36.0117H541.187L537.023 45.3426H542.323L538.538 54.3003L549.893 41.6103H544.594L548.001 36.0117Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M347.646 239.219H334.019L325.691 257.881H336.29L328.72 275.796L351.431 250.416H340.832L347.646 239.219Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M777.087 282.57H763.46L755.133 301.232H765.731L758.161 319.147L780.872 293.767H770.274L777.087 282.57Z",
      "fill": "#ED6400"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }