var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hero overflow-hide"
  }, [_c('div', {
    staticClass: "d-none d-xl-block"
  }, [_c('ContactHeroIcon', {
    staticStyle: {
      "margin-left": "-145px"
    }
  })], 1), _c('div', {
    staticClass: "d-none d-md-block d-xl-none"
  }, [_c('HeroTabletIcon', {
    staticClass: "heroTablet"
  })], 1), _c('div', {
    staticClass: "hero-content position-absolute",
    staticStyle: {
      "z-index": "0"
    }
  }, [_c('h1', {
    staticClass: "hero-header light-black text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("help.hero.header")) + " ")])]), _c('div', {
    staticClass: "d-md-none text-center mx-auto",
    staticStyle: {
      "max-width": "311px"
    }
  }, [_c('HeroMobileIcon', {
    staticStyle: {
      "transform": "translateX(-380px)"
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }