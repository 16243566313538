<template>
  <div class="hero overflow-hide">
    <!-- PC Hero Icons -->
    <div class="d-none d-xl-block">
      <ContactHeroIcon style="margin-left: -145px" />
    </div>
    <!-- Tablet Hero Icons -->
    <div class="d-none d-md-block d-xl-none">
      <HeroTabletIcon class="heroTablet" />
    </div>

    <div class="hero-content position-absolute" style="z-index: 0">
      <h1 class="hero-header light-black text-center">
        {{ $t("help.hero.header") }}
      </h1>
      <!-- <router-link to="/signup" class="d-flex justify-content-center link">
        <b-button
          variant="outline-primary"
          class="text-grey-primary-main round-btn-12-16-outline"
        >
          <p class="button-medium">
            {{ $t("help.hero.live-chat") }}
          </p>
        </b-button>
      </router-link> -->
    </div>
    <div class="d-md-none text-center mx-auto" style="max-width: 311px">
      <HeroMobileIcon style="transform: translateX(-380px)" />
    </div>
  </div>
</template>

<script>
import ContactHeroIcon from "../svg/hero/ContactHeroIcon.vue";
import HeroMobileIcon from "../svg/hero/HeroMobileIcon.vue";
import HeroTabletIcon from "../svg/hero/HeroTabletIcon.vue";
export default {
  components: {
    ContactHeroIcon,
    HeroMobileIcon,
    HeroTabletIcon,
  },
};
</script>

<style lang="scss" scoped>
.overflow-hide {
  @media (max-width: 767px) {
    overflow: hidden;
  }
}
h1 {
  z-index: -2;
  @media (max-width: 767px) {
    font-size: 50px !important;
    line-height: 100% !important;
    /* or 50px */

    text-align: center;
    letter-spacing: -0.01em !important;
    max-width: 213px !important;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    font-weight: bold;
    font-size: 43.3506px !important;
    line-height: 100%;
    /* or 43px */

    text-align: center;
    letter-spacing: -0.01em !important;
    // margin-left: -72px !important;
    margin-top: 20px !important;
    white-space: nowrap;
  }
  @media (min-width: 1200px) {
    white-space: nowrap;
  }
}

.round-btn-12-16-outline {
  text-align: center !important;

  @media (max-width: 767px) {
    margin-top: 33px !important;
    // margin-right: 33px !important;

    width: 165px !important;
    height: 48px !important;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin-top: 10px !important;
    margin-left: -18px !important;
    height: 32.6px !important;
    width: 111.5px !important;
    padding: auto !important;
  }
  @media (min-width: 1200px) {
    height: 48px !important;
    width: 200px !important;
    margin-left: -30% !important;
    margin-top: 8px !important;
  }
}

.button-medium {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */

  text-align: center;
  letter-spacing: 0.02em;
  white-space: nowrap;
  @media (max-width: 767px) {
    //font-family: "Open Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */

    text-align: center;
    letter-spacing: 0.02em;
  }
  @media (max-width: 1199px) and (min-width: 768px) {
    //font-family: "Open Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 10.8377px;
    line-height: 100%;
    /* identical to box height, or 11px */

    text-align: center;
    letter-spacing: 0.02em;
  }
}
.hero {
  @media (max-width: 767px) {
    margin-top: 10px;
  }
}
.hero-content {
  @media (min-width: 1200px) {
    top: 17% !important;
    // left: -6px !important;
    row-gap: 36px !important;
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    top: 14% !important;
    left: 0% !important;
    row-gap: 18px !important;
  }
  @media (max-width: 767px) {
    top: 8% !important;
    // left: 3% !important;
    row-gap: 20px !important;
  }
}
.heroTablet {
  @media (max-width: 991px) {
    transform: translateX(-120px);
  }
}
</style>
