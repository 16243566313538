var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "1871",
      "height": "934",
      "viewBox": "0 0 1871 934",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M1003.38 178.191C1003.38 212.787 975.001 240.862 939.952 240.862C904.903 240.862 876.521 212.787 876.521 178.191C876.521 143.595 904.903 115.519 939.952 115.519C975.001 115.519 1003.38 143.595 1003.38 178.191Z",
      "stroke": "#ED6400",
      "stroke-width": "3.03862"
    }
  }), _c('path', {
    attrs: {
      "d": "M988.19 221.113L1023.07 255.64",
      "stroke": "#ED6400",
      "stroke-width": "3.03862",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1015.19 247.84L1050.07 282.367",
      "stroke": "#ED6400",
      "stroke-width": "11.3948",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M118.524 1.78516C87.6015 130.575 79.3885 399.982 293.92 447.294C562.085 506.434 686.435 268.767 919.38 362.609C1152.32 456.45 1214.16 752.127 1419.6 752.127C1625.03 752.127 1757.5 701 1846.5 655.5",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-linecap": "round",
      "stroke-dasharray": "6 6"
    }
  }), _c('path', {
    attrs: {
      "d": "M1375.14 633.383L1367.71 641.324",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1367.72 633.383L1375.14 641.324",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1305 54.7184C1305 57.7652 1307.32 60.136 1310.06 60.136C1312.81 60.136 1315.12 57.7652 1315.12 54.7184C1315.12 51.6716 1312.81 49.3008 1310.06 49.3008C1307.32 49.3008 1305 51.6716 1305 54.7184Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M1269 589.066C1269 592.113 1271.32 594.484 1274.06 594.484C1276.8 594.484 1279.12 592.113 1279.12 589.066C1279.12 586.019 1276.8 583.648 1274.06 583.648C1271.32 583.648 1269 586.019 1269 589.066Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M503.002 273.632C503.002 276.679 505.321 279.05 508.063 279.05C510.804 279.05 513.123 276.679 513.123 273.632C513.123 270.586 510.804 268.215 508.063 268.215C505.321 268.215 503.002 270.586 503.002 273.632Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M521.252 65.2109L513.753 73.1523",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M513.752 65.2109L521.251 73.1523",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1081.25 164.715L1073.75 172.656",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1073.75 164.715L1081.25 172.656",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1382.25 351.785L1374.75 359.726",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1374.75 351.785L1382.25 359.726",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1078.26 41.6992L1070.76 49.6406",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1070.76 41.6992L1078.26 49.6406",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1260.59 421.449V426.859",
      "stroke": "#07B53B",
      "stroke-width": "1.70848",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1260.59 421.449V426.859",
      "stroke": "#07B53B",
      "stroke-width": "1.70848",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1250.04 424.445L1252.6 429.13",
      "stroke": "#07B53B",
      "stroke-width": "1.70848",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1242.32 432.617L1246.75 435.326",
      "stroke": "#07B53B",
      "stroke-width": "1.70848",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1239.5 443.793H1244.6",
      "stroke": "#07B53B",
      "stroke-width": "1.70848",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1242.32 454.966L1246.75 452.258",
      "stroke": "#07B53B",
      "stroke-width": "1.70848",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1250.04 463.145L1252.6 458.461",
      "stroke": "#07B53B",
      "stroke-width": "1.70848",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1260.59 466.14V460.73",
      "stroke": "#07B53B",
      "stroke-width": "1.70848",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1271.15 463.145L1268.59 458.461",
      "stroke": "#07B53B",
      "stroke-width": "1.70848",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1278.87 454.966L1274.45 452.258",
      "stroke": "#07B53B",
      "stroke-width": "1.70848",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1281.7 443.793H1276.59",
      "stroke": "#07B53B",
      "stroke-width": "1.70848",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1278.87 432.617L1274.45 435.326",
      "stroke": "#07B53B",
      "stroke-width": "1.70848",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1271.15 424.445L1268.59 429.13",
      "stroke": "#07B53B",
      "stroke-width": "1.70848",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M1260.6 457.783C1267.89 457.783 1273.81 451.519 1273.81 443.792C1273.81 436.065 1267.89 429.801 1260.6 429.801C1253.3 429.801 1247.38 436.065 1247.38 443.792C1247.38 451.519 1253.3 457.783 1260.6 457.783Z",
      "stroke": "#ED6400",
      "stroke-width": "0.854239",
      "stroke-miterlimit": "10"
    }
  }), _c('path', {
    attrs: {
      "d": "M1343.9 496.374H1233.45C1233.45 481.384 1242.84 468.634 1255.91 463.97C1259.18 448.541 1272.58 436.984 1288.62 436.984C1304.64 436.984 1318.03 448.517 1321.31 463.921C1334.45 468.549 1343.9 481.335 1343.9 496.374Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M1281.77 473.532H1230C1230 466.382 1234.4 460.301 1240.53 458.077C1242.06 450.719 1248.34 445.207 1255.86 445.207C1263.37 445.207 1269.65 450.707 1271.18 458.054C1277.34 460.261 1281.77 466.359 1281.77 473.532Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M245.448 93.9375C236.917 93.9375 230.002 100.853 230.002 109.384V176.121C230.002 184.652 236.917 191.568 245.448 191.568H253.179V210.76L270.503 191.568H387.341C395.871 191.568 402.787 184.652 402.787 176.121V109.384C402.787 100.853 395.871 93.9375 387.341 93.9375H245.448Z",
      "fill": "#07B53B"
    }
  }), _c('mask', {
    attrs: {
      "id": "path-35-inside-1_2613_48729",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M295.841 76C287.31 76 280.395 82.9155 280.395 91.4462V120.668C280.395 128.804 286.684 135.47 294.666 136.07V147.932L305.333 136.114H371.338C379.869 136.114 386.784 129.199 386.784 120.668V91.4462C386.784 82.9155 379.869 76 371.338 76H295.841Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M295.841 76C287.31 76 280.395 82.9155 280.395 91.4462V120.668C280.395 128.804 286.684 135.47 294.666 136.07V147.932L305.333 136.114H371.338C379.869 136.114 386.784 129.199 386.784 120.668V91.4462C386.784 82.9155 379.869 76 371.338 76H295.841Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M294.666 136.07H296.908V133.991L294.834 133.835L294.666 136.07ZM294.666 147.932H292.424V153.762L296.33 149.434L294.666 147.932ZM305.333 136.114V133.873H304.336L303.669 134.612L305.333 136.114ZM282.636 91.4462C282.636 84.1536 288.548 78.2418 295.841 78.2418V73.7582C286.072 73.7582 278.153 81.6773 278.153 91.4462H282.636ZM282.636 120.668V91.4462H278.153V120.668H282.636ZM294.834 133.835C288.013 133.322 282.636 127.622 282.636 120.668H278.153C278.153 129.986 285.355 137.618 294.498 138.306L294.834 133.835ZM292.424 136.07V147.932H296.908V136.07H292.424ZM296.33 149.434L306.997 137.617L303.669 134.612L293.002 146.43L296.33 149.434ZM371.338 133.873H305.333V138.356H371.338V133.873ZM384.543 120.668C384.543 127.961 378.631 133.873 371.338 133.873V138.356C381.107 138.356 389.026 130.437 389.026 120.668H384.543ZM384.543 91.4462V120.668H389.026V91.4462H384.543ZM371.338 78.2418C378.631 78.2418 384.543 84.1536 384.543 91.4462H389.026C389.026 81.6773 381.107 73.7582 371.338 73.7582V78.2418ZM295.841 78.2418H371.338V73.7582H295.841V78.2418Z",
      "fill": "#ED6400",
      "mask": "url(#path-35-inside-1_2613_48729)"
    }
  }), _c('rect', {
    attrs: {
      "x": "303.56",
      "y": "99.9141",
      "width": "60.3847",
      "height": "3",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "303.559",
      "y": "108.883",
      "width": "38.4952",
      "height": "3",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M1316.44 189.789C1324.49 189.789 1331.01 196.313 1331.01 204.362V267.324C1331.01 275.373 1324.49 281.897 1316.44 281.897H1309.15V300.004L1292.8 281.897H1182.57C1174.53 281.897 1168 275.372 1168 267.324V204.362C1168 196.313 1174.53 189.789 1182.57 189.789H1316.44Z",
      "fill": "#07B53B"
    }
  }), _c('mask', {
    attrs: {
      "id": "path-40-inside-2_2613_48729",
      "fill": "white"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M1295.08 163C1303.13 163 1309.65 169.524 1309.65 177.572V205.142C1309.65 212.817 1303.72 219.106 1296.19 219.673V230.864L1286.13 219.714H1223.85C1215.81 219.714 1209.28 213.19 1209.28 205.142V177.572C1209.28 169.524 1215.81 163 1223.85 163H1295.08Z"
    }
  })]), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M1295.08 163C1303.13 163 1309.65 169.524 1309.65 177.572V205.142C1309.65 212.817 1303.72 219.106 1296.19 219.673V230.864L1286.13 219.714H1223.85C1215.81 219.714 1209.28 213.19 1209.28 205.142V177.572C1209.28 169.524 1215.81 163 1223.85 163H1295.08Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M1296.19 219.673H1294.07V217.711L1296.03 217.564L1296.19 219.673ZM1296.19 230.864H1298.3V236.364L1294.62 232.281L1296.19 230.864ZM1286.13 219.714V217.599H1287.07L1287.7 218.297L1286.13 219.714ZM1307.54 177.572C1307.54 170.692 1301.96 165.115 1295.08 165.115V160.885C1304.3 160.885 1311.77 168.356 1311.77 177.572H1307.54ZM1307.54 205.142V177.572H1311.77V205.142H1307.54ZM1296.03 217.564C1302.47 217.08 1307.54 211.702 1307.54 205.142H1311.77C1311.77 213.932 1304.97 221.133 1296.35 221.782L1296.03 217.564ZM1298.3 219.673V230.864H1294.07V219.673H1298.3ZM1294.62 232.281L1284.56 221.131L1287.7 218.297L1297.76 229.447L1294.62 232.281ZM1223.85 217.599H1286.13V221.829H1223.85V217.599ZM1211.4 205.142C1211.4 212.022 1216.97 217.599 1223.85 217.599V221.829C1214.64 221.829 1207.17 214.358 1207.17 205.142H1211.4ZM1211.4 177.572V205.142H1207.17V177.572H1211.4ZM1223.85 165.115C1216.97 165.115 1211.4 170.692 1211.4 177.572H1207.17C1207.17 168.356 1214.64 160.885 1223.85 160.885V165.115ZM1295.08 165.115H1223.85V160.885H1295.08V165.115Z",
      "fill": "#ED6400",
      "mask": "url(#path-40-inside-2_2613_48729)"
    }
  }), _c('rect', {
    attrs: {
      "x": "1221.42",
      "y": "178.512",
      "width": "56.969",
      "height": "3",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "1221.42",
      "y": "186.973",
      "width": "36.3178",
      "height": "3",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M342.78 276.172V281.318",
      "stroke": "#07B53B",
      "stroke-width": "1.62518",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M342.78 276.172V281.318",
      "stroke": "#07B53B",
      "stroke-width": "1.62518",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M332.737 279.02L335.171 283.475",
      "stroke": "#07B53B",
      "stroke-width": "1.62518",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M325.398 286.797L329.606 289.374",
      "stroke": "#07B53B",
      "stroke-width": "1.62518",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M322.71 297.426H327.57",
      "stroke": "#07B53B",
      "stroke-width": "1.62518",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M325.398 308.053L329.606 305.477",
      "stroke": "#07B53B",
      "stroke-width": "1.62518",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M332.737 315.835L335.171 311.379",
      "stroke": "#07B53B",
      "stroke-width": "1.62518",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M342.78 318.685V313.539",
      "stroke": "#07B53B",
      "stroke-width": "1.62518",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M352.819 315.835L350.386 311.379",
      "stroke": "#07B53B",
      "stroke-width": "1.62518",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M360.164 308.053L355.956 305.477",
      "stroke": "#07B53B",
      "stroke-width": "1.62518",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M362.857 297.426H357.997",
      "stroke": "#07B53B",
      "stroke-width": "1.62518",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M360.164 286.797L355.956 289.374",
      "stroke": "#07B53B",
      "stroke-width": "1.62518",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M352.819 279.02L350.386 283.475",
      "stroke": "#07B53B",
      "stroke-width": "1.62518",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M342.782 310.736C349.724 310.736 355.351 304.777 355.351 297.426C355.351 290.076 349.724 284.117 342.782 284.117C335.841 284.117 330.214 290.076 330.214 297.426C330.214 304.777 335.841 310.736 342.782 310.736Z",
      "stroke": "#ED6400",
      "stroke-width": "0.81259",
      "stroke-miterlimit": "10"
    }
  }), _c('path', {
    attrs: {
      "d": "M376.061 347.44H271.002C271.002 333.18 279.931 321.052 292.364 316.615C295.472 301.938 308.222 290.945 323.481 290.945C338.717 290.945 351.455 301.915 354.575 316.569C367.076 320.971 376.061 333.134 376.061 347.44Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M385.09 318.758H333.381C333.381 311.738 337.776 305.767 343.895 303.583C345.425 296.357 351.7 290.945 359.21 290.945C366.709 290.945 372.979 296.346 374.514 303.56C380.667 305.727 385.09 311.715 385.09 318.758Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M858.371 28H848.211L842.002 41.7755H849.904L844.26 55L861.193 36.2653H853.291L858.371 28Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M519.884 352H505.061L496.002 372.408H507.531L499.296 392L524.002 364.245H512.473L519.884 352Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M1159.15 416H1141.68L1131 439.98H1144.59L1134.88 463L1164 430.388H1150.41L1159.15 416Z",
      "fill": "#ED6400"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }